import { computed, unref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { AUTH_LOGOUT } from '@fm-tools/core/store';
import Swal from 'sweetalert2';
import { getStoreUtils } from './store';
import { CartItem, FlatCart, InventoryItem, Link, Model, NavList, Role } from '@fm-tools/core';
import { NAV, ACCOUNT } from '@fm-tools/core/models';

export function getUtils() {
  const store = useStore();
  const router = useRouter();

  const { cart } = getStoreUtils();

  const auth = (link: Link): boolean => {
    if (!link.auth) return true;
    if (!store.getters.isAuthenticated) return false;
    let role = store.getters.profile.role;
    if (!role) return false;
    role = Role[role];
    return role >= link.auth;
  };

  const logout = (): void => {
    store.dispatch(AUTH_LOGOUT).then(() => {
      Swal.fire('Success', 'You have been logged out successfully.', 'success').then(() => {
        router.push('/login');
      });
    });
  };

  const nav = computed(() => {
    return NAV.filter((a: Link) => {
      return a.auth ? auth(a) : true;
    });
  });

  const account = computed((): NavList => ACCOUNT.filter((a: Link) => (a.auth ? store.getters.isAuthenticated : !store.getters.isAuthenticated)));

  const print = (...items: any[]) => console.log(...items);

  const bounded = ({ min = 1, max = 999, value }: { min?: number; max?: number; value: number }): number => {
    if (max === 0) max = 999;
    if (value < min) return min;
    if (value > max) return max;
    return value;
  };

  const isLink = (model: Model) => {
    const regex = /https?:\/\/(?:[a-zA-Z\d\-_]+\.)+[a-zA-Z\d]+(?:(?:\/[a-zA-Z\d%_\-/.]+)+\.+)+(?:jpg|jpeg|png|gif)/g;
    if (model == null || model.appearance == null || model.appearance == undefined) return '';
    const ret = regex.test(model.appearance);
    if (ret) {
      return getLink(model.appearance);
    } else {
      return '';
    }
  };

  const getLink = (link: string) => {
    const regex = /https?:\/\/(?:[a-zA-Z\d\-_]+\.)+[a-zA-Z\d]+(?:(?:\/[a-zA-Z\d%_\-/.]+)+\.+)+(?:jpg|jpeg|png|gif)/g;
    const ret = regex.exec(link);
    if (ret) {
      return ret[0];
    } else {
      return '';
    }
  };

  /*const copyToClipboard = (form: string) => {
    const copy = document.querySelector(form) as HTMLInputElement;
    if (copy == null) return;
    copy.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'success' : 'failed';
      console.log(msg);
    } catch (err) {
      console.error('Oops');
    }
    const selection = window.getSelection();
    if (selection == null) {
      return;
    }
    selection.removeAllRanges();
  };*/

  /*const copyToClipboard = (text: string) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };*/

  const copyToClipboard = async (text: string) => {
    const type = 'text/plain';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [blob.type]: blob })];
    await navigator.clipboard.write(data);
  };

  const sum = (item: CartItem | InventoryItem) => item.price.adjusted.reduce((total, item) => total + item.price * item.quantity, 0);
  const num = (item: CartItem | InventoryItem) => item.price.adjusted.reduce((total, item) => total + item.quantity, 0);

  const flatten = (): FlatCart =>
    unref(cart).reduce((flat, item) => {
      item.price.adjusted.reduce((_, price) => {
        flat.push({
          name: item.name,
          price: price.price,
          num: price.quantity,
          currency: item.price.currency,
          base: item.price.base,
          limit: item?.limit || 0,
          veteran: item.veteran,
          item,
        });
        return price;
      });
      return flat;
    }, [] as FlatCart);

  return { nav, auth, account, logout, print, bounded, isLink, getLink, copyToClipboard, sum, num, flatten };
}
