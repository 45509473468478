import { default as Axios } from 'axios';
import {
  ADMIN_USER_REQUEST,
  ADMIN_USER_SUCCESS,
  ADMIN_UPDATE_USER,
  ADMIN_UPDATE_CHARACTER,
  ADMIN_UPDATE_PARTNER,
  ADMIN_NEW_CHARACTER,
  ADMIN_NEW_PARTNER,
  FETCH_AUDITS,
  CHANGE_STATE,
  LOADING,
  ERROR,
  SUCCESS,
} from '../actions';

export default {
  state: () => ({}),

  getters: {},

  actions: {
    [ADMIN_USER_REQUEST]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }, username: string) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.get(`admin/user/${username}`)
          .then((resp) => {
            const user = resp.data.user;
            commit(ADMIN_USER_SUCCESS, user);
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [ADMIN_UPDATE_USER]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }, user: { name: string }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`admin/user/update/${user.name}`, user)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [ADMIN_NEW_CHARACTER]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { user, character }: { user: { name: string }; character: unknown }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`/characters/create/new/character/${user.name}`, character)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp.data);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [ADMIN_UPDATE_CHARACTER]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { user, character }: { user: { name: string }; character: { _id: string } }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`/characters/modify/character/${user.name}/${character._id}`, character)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp.data);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [ADMIN_UPDATE_PARTNER]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { user, character, partner }: { user: { name: string }; character: { _id: string }; partner: { _id: string } }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`/characters/modify/partner/${user.name}/${character._id}/${partner._id}`, partner)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp.data);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [ADMIN_NEW_PARTNER]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { user, character, partner }: { user: { name: string }; character: { _id: string }; partner: unknown }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`/characters/create/new/partner/${user.name}/${character._id}`, partner)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp.data);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [FETCH_AUDITS]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.get('admin/all/audits')
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp.data);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),
  },

  mutations: {},
};
