// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { fa } from 'vuetify/iconsets/fa';
import { md } from 'vuetify/iconsets/md';

const FMDarkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#212121',
    surface: '#212121',
    primary: '#FF9800',
    'primary-darken-1': '#FF6D00',
    secondary: '#FF9800',
    'secondary-darken-1': '#FF6D00',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
  variables: {},
};

export default createVuetify({
  theme: {
    defaultTheme: 'FMDarkTheme',
    themes: {
      FMDarkTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      md,
      fa,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

/*
// src/plugins/vuetify.ts
import { createApp } from 'vue'
import { createVuetify, ThemeDefinition } from 'vuetify'

const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  }
})
*/
