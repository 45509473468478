import { App } from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ isCompression: false });

export function useAxios(app: App<Element>) {
  return app.use(VueAxios, Axios);
}

export function setToken(token?: unknown): void {
  token = token || ls.get('user-token');
  Axios.defaults.headers.common['Authorization'] = '';
  delete Axios.defaults.headers.common['Authorization'];

  if (token) {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

Axios.defaults.baseURL = 'https://db.fm.omnious.org/api/';
