import { HumanModel, DigimonModel, CustomDigiModel } from '@fm-tools/core';

export enum Role {
  Member,
  Staff,
  Admin,
  Root,
}

export enum Names {
  Rookie = 'Child',
  Champion = 'Adult',
  Ultimate = 'Perfect',
  Mega = 'Ultimate',
  Ultra = 'Super Ultimate',
}

export enum PowerLevel {
  Rookie = 10,
  Champion = 30,
  Ultimate = 90,
  Mega = 270,
  Ultra = 810,
}

export const Boards = ['ANDC/Recoded/DCD', 'Digital Dreams'];

export const Levels = [
  'Egg',
  'Fresh/Baby I',
  'In-Training/Baby II',
  'Rookie/Child',
  'Champion/Adult',
  'Ultimate/Perfect',
  'Mega/Ultimate',
  'Ultra/Super Ultimate',
];

export function HumanFactory(human: Partial<HumanModel> = {}): HumanModel {
  return Object.assign(human, {
    name: '',
    age: '',
    gender: '',
    tamerClass: '',
    subclass: '',
    personality: '',
    history: '',
    type: 'Human',
  }) as HumanModel;
}

export function DigimonFactory(digimon: Partial<DigimonModel> = {}): DigimonModel {
  return Object.assign(digimon, {
    nickname: '',
    gender: '',
    subclass: '',
    mainForm: '',
    starting: '',
    personality: '',
    history: '',
    image: false,
    type: 'Digimon',
  }) as DigimonModel;
}

export function CustomFactory(custom: Partial<CustomDigiModel> = {}): CustomDigiModel {
  return Object.assign(custom, {
    name: '',
    level: '',
    gender: '',
    attacks: '',
    abilities: '',
    type: 'Custom Digimon',
  }) as CustomDigiModel;
}
