import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { REMOVE_FROM_CART, SET_ACTIVE_CHARACTER, SET_EDIT_CHARACTER, SET_EDIT_PARTNER, UPLOAD_SHOP_JSON } from '@fm-tools/core/store';
import { Cart, CartItem, Character, CharType, Class, Classes, Partner, PL, Role, Shop, Subclass, User } from '@fm-tools/core';

export function getStoreUtils() {
  const store = useStore();

  const loading = computed((): boolean => store.getters.status === 'loading');

  const characters = computed((): Character[] => store.getters.characters);

  const activeCharacter = computed((): Character => store.getters.character);

  const cart = computed((): Cart => store.getters.cart);

  const cartSize = computed((): number => cart.value.length);

  const profile = computed((): User => store.getters.profile);

  const profileTab = computed((): string => store.getters.profileTab);

  const classes = computed((): Class[] => store.getters.classes);

  const editCharacter = computed((): Character => store.getters.editCharacter);

  const editPartner = computed((): Partner => store.getters.editPartner);

  const sortedShops = computed((): Shop[] => store.getters.sortedShops);

  const sortedPLs = computed((): PL[] => store.getters.sortedPLs);

  const setCharacter = (character: Character | null): void => store.commit(SET_ACTIVE_CHARACTER, character);

  const setEditCharacter = (character: Character = activeCharacter.value) => store.commit(SET_EDIT_CHARACTER, character);

  const setEditPartner = (partner: Partner | null) => store.commit(SET_EDIT_PARTNER, partner);

  const isActive = (character: Character | null): boolean => {
    if (character && activeCharacter.value) return activeCharacter.value?._id === character._id;
    return false;
  };

  const isAuthenticated = computed((): boolean => store.getters.isAuthenticated);

  const setupClasses = (type: CharType) => {
    const cls = ref({} as Classes);
    const classNames = ref([] as string[]);

    for (const c of classes.value) {
      if (!c.hidden || (Role[profile.value.role] >= Role['Staff' as never] && c.type === type)) {
        cls.value[c.name] = {
          name: c.name,
          type: c.type,
          description: c.description,
          hidden: c.hidden,
          subclasses: c.subclasses.filter((subclass: Subclass) => !subclass.hidden || Role[profile.value.role] >= Role['Staff' as never]),
        };
        classNames.value.push(c.name);
      } else continue;
    }
    return { classes: cls, classNames };
  };

  const upload_shops = () => store.dispatch(UPLOAD_SHOP_JSON);

  const remove_item = (item: CartItem) => store.commit(REMOVE_FROM_CART, item);

  return {
    loading,
    characters,
    activeCharacter,
    cart,
    cartSize,
    profile,
    profileTab,
    classes,
    editCharacter,
    editPartner,
    sortedShops,
    sortedPLs,
    setCharacter,
    setEditCharacter,
    setEditPartner,
    isActive,
    isAuthenticated,
    setupClasses,
    upload_shops,
    remove_item,
  };
}
