import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from '@fm-tools/core/store';
import vuetify from '@fm-tools/core/plugins/vuetify';
import { loadFonts } from '@fm-tools/core/plugins/webfontloader';
import axios from 'axios';
import VueAxios from 'vue-axios';

loadFonts();

createApp(App).use(router).use(store).use(vuetify).use(VueAxios, axios).mount('#app');
