import {
  SHOP_REQUEST,
  SHOP_SUCCESS,
  CREATE_SHOP,
  UPDATE_SHOP,
  DELETE_SHOP,
  CREATE_SHOP_ITEM,
  UPDATE_SHOP_ITEM,
  DELETE_SHOP_ITEM,
  UPLOAD_SHOP_JSON,
  CHANGE_STATE,
  ERROR,
  LOADING,
  SUCCESS,
} from '../actions';
import Axios from 'axios';
import { Shop } from '@fm-tools/core';

export default {
  state: () => ({
    shops: [] as Shop[],
    shop: {} as Shop,
  }),

  getters: {
    shop: (state: { shop: Shop }) => state.shop,
    shops: (state: { shops: Shop[] }) => state.shops,
    sortedShops: (state: { shops: Shop[] }) =>
      [...state.shops].sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      }),
    sortedInventory: (state: { shop: Shop }) =>
      [...state.shop.inventory]
        .sort((a, b) => {
          return a.category < b.category ? -1 : a.category > b.category ? 1 : 0;
        })
        .forEach((category) => {
          category.items = [...category.items].sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });
        }),
  },

  actions: {
    [SHOP_REQUEST]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }, shop?: string) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.get(shop ? `shop/shop/${shop}` : 'shop/all/shops')
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            commit(SHOP_SUCCESS, shop ? resp.data.shop : resp.data.shops);
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CREATE_SHOP]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, shop: unknown) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post('shop/new/shop', shop)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(SHOP_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [UPDATE_SHOP]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, { shop, name }: { shop: unknown; name: string }) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`shop/update/shop/${name}`, shop)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(SHOP_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_SHOP]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, name: string): Promise<void> =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.delete(`shop/delete/shop/${name}`)
          .then(() => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(SHOP_REQUEST).then(() => resolve());
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [DELETE_SHOP]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, name: string): Promise<void> =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.delete(`shop/delete/shop/${name}`)
          .then(() => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(SHOP_REQUEST).then(() => resolve());
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CREATE_SHOP_ITEM]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, { shop, item }: { shop: string; item: unknown }) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`shop/new/item/${shop}`, item)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(SHOP_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [UPDATE_SHOP_ITEM]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { item, shop, name }: { item: unknown; shop: string; name: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`shop/update/item/${shop}/${name}`, item)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(SHOP_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_SHOP_ITEM]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { shop, name }: { shop: string; name: string }
    ): Promise<void> =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.delete(`shop/delete/item/${shop}/${name}`)
          .then(() => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(SHOP_REQUEST).then(() => resolve());
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [UPLOAD_SHOP_JSON]: async ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      const json = (await import('@fm-tools/core/models')).ShopsJson;
      Axios.post('shop/upload', json)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(SHOP_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },
  },

  mutations: {
    [SHOP_SUCCESS]: (state: { shops: any[]; shop: unknown }, resp?: unknown): void => {
      if (!resp) return;
      if (Array.isArray(resp)) {
        state.shops = resp;
        state.shop = {};
      } else state.shop = resp;
    },
  },
};
