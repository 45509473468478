import { default as Axios } from 'axios';
import {
  POWER_LEVEL_REQUEST,
  POWER_LEVEL_SUCCESS,
  CREATE_POWER_LEVEL,
  DELETE_POWER_LEVEL,
  UPDATE_POWER_LEVEL,
  CHANGE_STATE,
  LOADING,
  SUCCESS,
  ERROR,
} from '../actions';
import { PL } from '@fm-tools/core';

export default {
  state: () => ({
    powerLevels: [] as PL[],
  }),

  getters: {
    powerLevels: (state: { powerLevels: PL[] }) => state.powerLevels,
    sortedPLs: (state: { powerLevels: PL[] }) => {
      return [...state.powerLevels].sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
    },
  },

  actions: {
    [POWER_LEVEL_REQUEST]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.get('ep/all/pls')
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            commit(POWER_LEVEL_SUCCESS, resp.data.pls);
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CREATE_POWER_LEVEL]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, pl: unknown) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post('ep/new/pl', pl)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(POWER_LEVEL_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [UPDATE_POWER_LEVEL]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, pl: unknown) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`ep/update/pl/${name}`, pl)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(POWER_LEVEL_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_POWER_LEVEL]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, powerLevel: string) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`ep/delete/pl/${powerLevel}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(POWER_LEVEL_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },
  },

  mutations: {
    [POWER_LEVEL_SUCCESS]: (state: { powerLevels: unknown }, resp: unknown | unknown[]): void => {
      if (!resp) return;
      state.powerLevels = resp;
    },
  },
};
