import { ADD_TO_CART, REMOVE_FROM_CART, EDIT_IN_CART, EMPTY_CART } from '../actions';
import { Cart, CartItem } from '@fm-tools/core';

export default {
  state: () => ({
    cart: [] as Cart,
  }),

  getters: {
    cart: (state: { cart: Cart }) => state.cart,
  },

  actions: {},

  mutations: {
    [ADD_TO_CART]: (state: { cart: Cart }, item: CartItem) => {
      for (const i of state.cart) {
        if (i.name === item.name) {
          for (const a of [...i.price.adjusted]) {
            const it = item.price.adjusted.find((b) => b.price === a.price);
            if (!it) item.price.adjusted.push(a);
            else {
              it.quantity += a.quantity;
              if (it.quantity > (i.limit || 999)) it.quantity = i.limit ? i.limit : 999;
              else if (it.quantity < 0) it.quantity = 0;
            }
          }
        } else state.cart.push(item);
      }
    },

    [REMOVE_FROM_CART]: (state: { cart: Cart }, item: CartItem) => (state.cart = state.cart.filter((it) => it.name !== item.name)),

    [EDIT_IN_CART]: (state: { cart: Cart }, item: CartItem) => {
      const cartItem = state.cart.find((it) => it.name === item.name);
      if (!cartItem) return;
      for (const a of [...cartItem.price.adjusted]) {
        const it = item.price.adjusted.find((b) => b.price === a.price);
        if (!it) return;
        else {
          it.quantity += a.quantity;
          if (it.quantity > (cartItem.limit || 999)) it.quantity = cartItem.limit ? cartItem.limit : 999;
          else if (it.quantity < 0) it.quantity = 0;
        }
      }
    },

    [EMPTY_CART]: (state: { cart: Cart[] }) => (state.cart = []),
  },
};
