import { default as Axios } from 'axios';
import {
  FORMULA_REQUEST,
  FORMULA_SUCCESS,
  CREATE_FORMULA,
  DELETE_FORMULA,
  UPDATE_FORMULA,
  EVALUATE_FORMULA,
  CHANGE_STATE,
  LOADING,
  SUCCESS,
  ERROR,
} from '../actions';
import { Formula } from '@fm-tools/core';

export default {
  state: () => ({
    formulas: [] as Formula[],
  }),

  getters: {
    formulas: (state: { formulas: Formula[] }) => state.formulas,
    sortedFormulas: (state: { formulas: Formula[] }) => [...state.formulas].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
  },

  actions: {
    [FORMULA_REQUEST]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.get('ep/all/formulas')
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            commit(FORMULA_SUCCESS, resp.data.formulas);
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CREATE_FORMULA]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { formula, name }: { formula: string; name: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post('ep/new/formula', { formula: formula, name: name })
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(FORMULA_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [UPDATE_FORMULA]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { formula, name }: { formula: string; name: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`ep/update/formula/${name}`, { formula: formula, name: name })
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(FORMULA_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_FORMULA]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, formula: string) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`ep/delete/formula/${formula}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(FORMULA_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [EVALUATE_FORMULA]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }, { formula, params }: { formula: string; params: unknown[] }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`ep/calc/${formula}`, params)
          .then((resp) => {
            commit(FORMULA_SUCCESS);
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp.data.result);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),
  },

  mutations: {
    [FORMULA_SUCCESS]: (state: { formulas: unknown[] }, resp?: unknown[]) => {
      if (!resp) return;
      state.formulas = resp;
    },
  },
};
