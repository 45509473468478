import { default as Axios } from 'axios';
import {
  CLASS_REQUEST,
  CLASS_SUCCESS,
  CREATE_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  DELETE_SUBCLASS,
  UPDATE_SUBCLASS,
  CREATE_SUBCLASS,
  MOVE_SUBCLASS,
  CHANGE_STATE,
  LOADING,
  SUCCESS,
  ERROR,
} from '../actions';
import { Class } from '@fm-tools/core';

export default {
  state: () => ({
    classes: [] as Class[],
  }),

  getters: {
    classes: (state: { classes: Class[] }) => state.classes,
  },

  actions: {
    [CLASS_REQUEST]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.get('ca/all/classes')
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            commit(CLASS_SUCCESS, resp.data.classes);
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CREATE_CLASS]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, cls: unknown) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post('ca/new/class', cls)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [UPDATE_CLASS]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, { cls, name }: { cls: unknown; name: string }) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`ca/update/class/${name}`, cls)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_CLASS]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, cls: string) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`ca/delete/class/${cls}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [CREATE_SUBCLASS]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { cls, subclass }: { cls: string; subclass: unknown }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`ca/new/subclass/${cls}`, subclass)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [UPDATE_SUBCLASS]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { subclass, cls, name }: { subclass: unknown; cls: string; name: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`ca/update/subclass/${cls}/${name}`, subclass)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_SUBCLASS]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { cls, subclass }: { cls: string; subclass: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`ca/delete/subclass/${cls}/${subclass}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [DELETE_SUBCLASS]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { cls, subclass }: { cls: string; subclass: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`ca/delete/subclass/${cls}/${subclass}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [MOVE_SUBCLASS]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { oldcls, newcls, subclass }: { oldcls: string; newcls: string; subclass: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.post(`ca/move/subclass/${oldcls}/${newcls}/${subclass}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(CLASS_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },
  },

  mutations: {
    [CLASS_SUCCESS]: (state: { classes: unknown[] }, resp?: unknown[]) => {
      if (!resp) return;
      state.classes = resp;
    },
  },
};
