import { CustomDigiModel, DigimonModel, HumanModel, Model } from '@fm-tools/core';

function getAppearance(model: HumanModel | DigimonModel | CustomDigiModel) {
  let appearance = '';
  const regex = /^\[img\]https?:\/\/(?:[a-zA-Z\d\-_]+\.)+[a-zA-Z\d]+(?:(?:\/[a-zA-Z\d%_\-/.]+)+\.+)+(?:jpg|jpeg|png|gif)\[\/img\]$/;
  if (model.imageLink) regex.test(model.imageLink) ? (appearance = model.imageLink) : (appearance = `[img]${model.imageLink}[/img]`);
  return appearance ? appearance : model.appearance;
}

function _getHumanTemplate(human: HumanModel) {
  const appearance = getAppearance(human);
  return `
    [SIZE=14]Tamer Profile:[/SIZE]
    [SIZE=7][b]General:[/b][/SIZE]
    [b]Name:[/b] ${human.name}
    [b]Age:[/b] ${human.age}
    [b]Gender:[/b] ${human.gender}
    [b]Class:[/b] ${human.tamerClass}
    [b]Subclass:[/b] ${human.subclass}
    ${human.secondClass ? `[b]Second Class:[/b] ${human.secondClass}` : ''}
    ${human.secondClass && human.secondSubclass ? `[b]Second Subclass:[/b] ${human.secondSubclass}` : ''}
    ${human.veteran ? `[b]Veteran:[/b] ${human.veteran}` : ''}
    [SIZE=7][b]About your Tamer:[/b][/SIZE]
    ${appearance ? `[b]Appearance:[/b] ${appearance}` : ''}
    ${human.line ? `[b]Digivolution Line:[/b] ${human.line}` : ''}
    [b]Personality:[/b] ${human.personality}
    [b]History:[/b] ${human.history}
    [hr]
  `.replace(/^\s*[\r\n]*/gm, '');
}

function _getDigimonTemplate(digimon: DigimonModel) {
  const appearance = getAppearance(digimon);
  return `
    [SIZE=14]Digimon Profile:[/SIZE]
    [SIZE=7][b]General:[/b][/SIZE]
    [b]Nickname:[/b] ${digimon.nickname}
    [b]Gender:[/b] ${digimon.gender}
    [b]Subclass:[/b] ${digimon.subclass}
    ${digimon.veteran ? `[b]Veteran:[/b] ${digimon.veteran}` : ''}
    [b]Main Form:[/b] ${digimon.mainForm}
    [b]Starting Form:[/b] ${digimon.starting}
    [b]Digivolution Line:[/b] ${digimon.line}
    [SIZE=7][b]About your Digimon:[/b][/SIZE]
    ${digimon.image ? `[b]Appearance:[/b] ${appearance}` : digimon.defining ? `[b]Defining Marks:[/b] ${digimon.defining}` : ''}
    [b]Personality:[/b] ${digimon.personality}
    [b]History:[/b] ${digimon.history}
    [hr]
  `.replace(/^\s*[\r\n]*/gm, '');
}

function _getCustomTemplate(custom: CustomDigiModel) {
  const appearance = getAppearance(custom);
  return `
    [b]Name:[/b] ${custom.name}
    [b]Level:[/b] ${custom.level}
    [b]Gender:[/b] ${custom.gender}
    ${custom.to ? `[b]Digivolves to:[/b] ${custom.to}` : ''}
    ${custom.from ? `[b]Digivolves from:[/b] ${custom.from}` : ''}
    ${appearance ? `[b]Appearance:[/b] ${appearance}` : ''}
    [b]Attacks:[/b] ${custom.attacks}
    [b]Abilities:[/b] ${custom.abilities}
    [b]Other:[/b] ${custom.other}
    [hr]
  `.replace(/^\s*[\r\n]*/gm, '');
}

export function getTemplate<T extends Model>(model: T): string {
  console.log(model);
  switch (model.type) {
    case 'Human':
      return _getHumanTemplate(model as HumanModel);
    case 'Digimon':
      return _getDigimonTemplate(model as DigimonModel);
    case 'Custom Digimon':
      return _getCustomTemplate(model as CustomDigiModel);
    default:
      return '';
  }
}
