export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const VERIFY_RESET_PASSWORD = 'VERIFY_RESET_PASSWORD';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const VERIFY_CHANGE_EMAIL = 'VERIFY_CHANGE_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
