import { CHECK_VERSION } from '../actions';
import { default as packageJson } from '../../../../package.json';

export default {
  state: () => ({
    packageVersion: packageJson.version || '0.0.0',
  }),

  getters: {
    appVersion: (state: { packageVersion: string }): string => state.packageVersion,
  },

  actions: {
    [CHECK_VERSION]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }): void => commit(CHECK_VERSION),
  },

  mutations: {
    [CHECK_VERSION]: (state: { packageVersion: string }) => (state.packageVersion = packageJson.version || '0.0.0'),
  },
};
