import { TO_INVENTORY } from '../actions';

export default {
  state: () => ({
    profileTab: '',
  }),

  getters: {
    profileTab: (state: { profileTab: string }): string => state.profileTab,
  },

  actions: {},

  mutations: {
    [TO_INVENTORY]: (state: { profileTab: string }, tab?: string) => (state.profileTab = tab ?? 'tab-Info'),
  },
};
