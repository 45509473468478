import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  RESET_PASSWORD,
  VERIFY_RESET_PASSWORD,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  VERIFY_CHANGE_EMAIL,
  USER_REQUEST,
  CHANGE_STATE,
  LOADING,
  SUCCESS,
  ERROR,
  EMPTY_CART,
} from '../actions';
import { default as Axios } from 'axios';
import SecureLS from 'secure-ls';
import { setToken } from '@fm-tools/core/plugins/axios';
const ls = new SecureLS({ isCompression: false });

export default {
  state: () => ({
    token: (ls.get('user-token') as string) || '',
    hasLoadedOnce: false as boolean,
  }),

  getters: {
    isAuthenticated: (state: { token: string }) => !!state.token,
  },

  actions: {
    [AUTH_REQUEST]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, user: unknown) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('user/login', user)
          .then((resp) => {
            const token = resp.data.token;
            setToken(token);
            ls.set('user-token', token);
            commit(CHANGE_STATE, SUCCESS, { root: true });
            commit(AUTH_SUCCESS, token);
            dispatch(USER_REQUEST);
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            ls.remove('user-token');
            ls.remove('jwt');
            reject(err);
          });
      }),

    [AUTH_LOGOUT]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }): Promise<void> =>
      new Promise((resolve) => {
        commit(AUTH_LOGOUT);
        ls.remove('user-token');
        ls.remove('jwt');
        sessionStorage.clear();
        commit(EMPTY_CART);
        resolve();
      }),

    [RESET_PASSWORD]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }, username: string) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('reset/password', { username: username })
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [VERIFY_RESET_PASSWORD]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { username, token, password }: { username: string; token: string; password: string }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('reset/verify/password', { username: username, token: token, password: password })
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CHANGE_PASSWORD]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { password, new_password }: { password: string; new_password: string }
    ): Promise<void> =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('user/change/password', { password: password, new_password: new_password })
          .then(() => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve();
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [CHANGE_EMAIL]: (
      { commit }: { commit: CallableFunction; dispatch: CallableFunction },
      { password, new_email }: { password: string; new_email: string }
    ): Promise<void> =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('user/change/email', { password: password, new_email: new_email })
          .then(() => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve();
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [VERIFY_CHANGE_EMAIL]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }, { username, token }: { username: string; token: string }) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('user/verify/change/email', { username: username, token: token })
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            resolve(resp);
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),
  },

  mutations: {
    [AUTH_SUCCESS]: (state: { token: string }, token?: string) => (state.token = token || ''),
    [AUTH_LOGOUT]: (state: { token: string }) => (state.token = ''),
  },
};
