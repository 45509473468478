import { USER_REQUEST, USER_SUCCESS, USER_CREATE, USER_REQUEST_ALL, LOADING, ERROR, SUCCESS, CHANGE_STATE, AUTH_LOGOUT, AUTH_REQUEST } from '../actions';
//import apiCall
import axios from 'axios';
import SecureLS from 'secure-ls';
import { setToken } from '@fm-tools/core/plugins/axios';
import { User } from '@fm-tools/core';
const ls = new SecureLS({ isCompression: false });

export default {
  state: () => ({
    profile: {} as User,
    token: {} as string,
  }),

  getters: {
    profile: (state: { profile: User }) => state.profile,
    isProfileLoaded: (state: { profile: User }) => !!state.profile.name,
    characters: (state: { profile: User }) => state.profile.characters,
  },

  actions: {
    [USER_REQUEST]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }): void => {
      commit(CHANGE_STATE, LOADING, { root: true });
      axios
        .get('user/me')
        .then((resp) => {
          const token = resp.data.token;
          if (token) {
            setToken(token);
            ls.set('user-token', token);
          }
          commit(USER_SUCCESS, resp.data);
          commit(CHANGE_STATE, SUCCESS, { root: true });
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
          dispatch(AUTH_LOGOUT);
        });
    },
  },

  [USER_CREATE]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, user: unknown): Promise<unknown> =>
    new Promise((resolve, reject) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      axios
        .post('user/register', user)
        .then((resp) => {
          const token = resp.data.token;
          setToken(token);
          ls.set('user-token', token);
          commit(CHANGE_STATE, SUCCESS, { root: true });
          dispatch(AUTH_REQUEST, user);
          resolve(resp);
        })
        .catch((err) => {
          commit(CHANGE_STATE, ERROR, { root: true });
          ls.remove('user-token');
          ls.remove('jwt');
          reject(err);
        });
    }),

  [USER_REQUEST_ALL]: ({ commit }: { commit: CallableFunction; dispatch: CallableFunction }): Promise<unknown> =>
    new Promise((resolve, reject) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      axios
        .get('user/all/users')
        .then((resp) => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          resolve(resp.data.users);
        })
        .catch((err) => {
          commit(CHANGE_STATE, ERROR, { root: true });
          reject(err);
        });
    }),

  mutations: {
    [USER_SUCCESS]: (state: { profile: { characters: any[] }; character: { _id: any }; token: any }, resp?: any): void => {
      state.profile = resp;
      if (state.character) {
        const character = state.profile.characters.find((character) => character._id === state.character._id);
        if (character) state.character = character;
      }
      if (resp?.token) state.token = resp.token;
    },

    [AUTH_LOGOUT]: (state: { profile: unknown }): void => {
      state.profile = {};
    },
  },
};
