export const NEW_CHARACTER = 'NEW_CHARACTER';
export const NEW_PARTNER = 'NEW_PARTNER';
export const UPDATE_CHARACTER = 'UPDATE_CHARACTER';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const DELETE_CHARACTER = 'DELETE_CHARACTER';
export const DELETE_PARTNER = 'DELETE_PARTNER';

export const SET_ACTIVE_CHARACTER = 'SET_ACTIVE_CHARACTER';
export const SET_EDIT_CHARACTER = 'SET_EDIT_CHARACTER';
export const SET_EDIT_PARTNER = 'SET_EDIT_PARTNER';

export const BUY_ITEMS = 'BUY_ITEMS';
export const SELL_ITEM = 'SELL_ITEM';
