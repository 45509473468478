import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import store from '@fm-tools/core/store';
import { Role } from '@fm-tools/core';
import { SET_EDIT_CHARACTER, SET_EDIT_PARTNER } from '@fm-tools/core/store/actions';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/FMHome.vue'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/charapp',
    name: 'Character App Maker',
    component: async () => await import('@fm-tools/charapp'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/epcalc',
    name: 'epCalc',
    component: async () => await import('@fm-tools/epcalc'),
    meta: { transitionName: 'slide' },
  },
  {
    path: '/login',
    name: 'login',
    component: async () => (await import('@fm-tools/auth')).FMLogin,
    meta: { transitionName: 'slide', noAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: CallableFunction) => {
  if ((from.name === 'My Characters' && to.name !== 'My Partners') || (from.name === 'My Partners' && to.name !== 'My Characters'))
    store.commit(SET_EDIT_CHARACTER, null);
  if (from.name === 'My Partners') store.commit(SET_EDIT_PARTNER, null);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }
  if (to.matched.some((record) => record.meta.noAuth)) {
    if (store.getters.isAuthenticated) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  }
  if (to.matched.some((record) => record.meta.requiresRole)) {
    if (!store.getters.isAuthenticated || !store.getters.profile.role || Role[store.getters.profile.role] < Role[to.meta.requiresRole as any]) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresEditChar)) {
    if (!store.getters.editCharacter)
      next({
        path: '/profile',
      });
    else next();
  }

  next();
});

export default router;
