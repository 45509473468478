import { ref, Ref } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

const DEFAULT_TRANSITION = 'fade';
const DEFAULT_TRANSITION_MODE = 'out-in';
const DEFAULT_TRANSITION_ENTER_ACTIVE_CLASS = '';

export function getTransitions(
  transition: string | undefined = DEFAULT_TRANSITION,
  mode: 'default' | 'in-out' | 'out-in' | undefined = DEFAULT_TRANSITION_MODE,
  active: string | undefined = DEFAULT_TRANSITION_ENTER_ACTIVE_CLASS
) {
  const transitionName: Ref<string | undefined> = ref(transition);
  const transitionMode: Ref<'default' | 'in-out' | 'out-in' | undefined> = ref(mode);
  const transitionEnterActiveClass: Ref<string | undefined> = ref(active);

  const beforeEach = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    let name: string | undefined = (to.meta.transitionName as string) || (from.meta.transitionName as string) || transitionName.value;

    if (name === 'slide') {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      name = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    transitionEnterActiveClass.value = `${name}-enter-active`;

    if (to.meta.transitionName === 'zoom') {
      transitionMode.value = 'in-out';
      transitionEnterActiveClass.value = 'zoom-enter-active';
      // Disable scrolling in the background.
      document.body.style.overflow = 'hidden';
    }

    if (from.meta.transitionName === 'zoom') {
      transitionMode.value = undefined;
      transitionEnterActiveClass.value = undefined;
      // Enable scrolling again.
      document.body.style.overflow = '';
    }

    transitionName.value = name;
    next();
  };

  return {
    transitionName,
    transitionMode,
    transitionEnterActiveClass,
    beforeEach,
  };
}
