import { default as Axios } from 'axios';
import {
  NEW_CHARACTER,
  SET_ACTIVE_CHARACTER,
  SET_EDIT_CHARACTER,
  UPDATE_CHARACTER,
  SET_EDIT_PARTNER,
  NEW_PARTNER,
  UPDATE_PARTNER,
  DELETE_CHARACTER,
  DELETE_PARTNER,
  BUY_ITEMS,
  SELL_ITEM,
  USER_REQUEST,
  CHANGE_STATE,
  LOADING,
  SUCCESS,
  ERROR,
} from '../actions';
import { Character, Partner } from '@fm-tools/core';

export default {
  state: () => ({
    character: {} as Character,
    editCharacter: {} as Character,
    editPartner: {} as Partner,
  }),

  getters: {
    character: (state: { character: Character }) => state.character,
    editCharacter: (state: { editCharacter: Character }) => state.editCharacter,
    editPartner: (state: { editPartner: Partner }) => state.editPartner,
  },

  actions: {
    [NEW_CHARACTER]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, character: unknown) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post('characters/new/character', character)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(USER_REQUEST).then(() => resolve(resp.data));
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [UPDATE_CHARACTER]: ({ commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction }, character: unknown) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`characters/update/character/${(character as { _id: unknown })._id}`, character)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(USER_REQUEST).then(() => resolve(resp.data));
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [DELETE_CHARACTER]: ({ state, commit, dispatch }: { state: any; commit: CallableFunction; dispatch: CallableFunction }, character: unknown) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`characters/delete/character/${(character as { _id: unknown })._id}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          if (state.character?._id === (character as any)?._id) commit(SET_ACTIVE_CHARACTER, null);
          dispatch(USER_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [NEW_PARTNER]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { partner, character }: { partner: unknown; character: string }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`characters/new/partner/${character}`, partner)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            commit(NEW_PARTNER, partner);
            dispatch(USER_REQUEST).then(() => resolve(resp.data));
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [UPDATE_PARTNER]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { partner, character }: { partner: unknown; character: string }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`characters/update/partner/${character}/${(partner as { _id: unknown })._id}`, partner)
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(USER_REQUEST).then(() => resolve(resp.data));
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [DELETE_PARTNER]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { character, partner }: { character: string; partner: string }
    ) => {
      commit(CHANGE_STATE, LOADING, { root: true });
      Axios.delete(`characters/delete/partner/${character}/${partner}`)
        .then(() => {
          commit(CHANGE_STATE, SUCCESS, { root: true });
          commit(DELETE_PARTNER, partner);
          dispatch(USER_REQUEST);
        })
        .catch(() => {
          commit(CHANGE_STATE, ERROR, { root: true });
        });
    },

    [BUY_ITEMS]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { character, cart, total }: { character: string; cart: unknown; total: unknown }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`characters/buy/items/${character}`, { cart: cart, total: total })
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(USER_REQUEST).then(() => resolve(resp.data));
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),

    [SELL_ITEM]: (
      { commit, dispatch }: { commit: CallableFunction; dispatch: CallableFunction },
      { character, item, quantity, price }: { character: string; item: unknown; quantity: number; price: number }
    ) =>
      new Promise((resolve, reject) => {
        commit(CHANGE_STATE, LOADING, { root: true });
        Axios.post(`characters/sell/item/${character}`, { item: item, quantity: quantity, price: price })
          .then((resp) => {
            commit(CHANGE_STATE, SUCCESS, { root: true });
            dispatch(USER_REQUEST).then(() => resolve(resp.data));
          })
          .catch((err) => {
            commit(CHANGE_STATE, ERROR, { root: true });
            reject(err);
          });
      }),
  },

  mutations: {
    [SET_ACTIVE_CHARACTER]: (state: { character: any }, character: any) => (state.character = character),
    [SET_EDIT_CHARACTER]: (state: { editCharacter: any }, character: any) => (state.editCharacter = character),
    [SET_EDIT_PARTNER]: (state: { editPartner: any }, partner: any) => (state.editPartner = partner),
    [NEW_PARTNER]: (state: { editCharacter: { partners?: any[] } }, partner: any) => state.editCharacter?.partners?.push(partner),
    [DELETE_PARTNER]: (state: { character: { partners: any[] } }, partner: { _id: any }) =>
      (state.character.partners = state.character.partners.filter((p: { _id: any }) => p._id !== partner._id)),
  },
};
