export const CLASS_REQUEST = 'CLASS_REQUEST';
export const CLASS_SUCCESS = 'CLASS_SUCCESS';
export const CLASS_ERROR = 'CLASS_ERROR';
export const CREATE_CLASS = 'CREATE_CLASS';
export const CREATE_SUBCLASS = 'CREATE_SUBCLASS';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const UPDATE_SUBCLASS = 'UPDATE_SUBCLASS';
export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_SUBCLASS = 'DELETE_SUBCLASS';
export const MOVE_SUBCLASS = 'MOVE_SUBCLASS';
