import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ isCompression: false });
import { admin, auth, cart, characters, classes, formulas, nav, powerLevels, shops, user, version } from './modules';
import { CHANGE_STATE } from './actions';

const debug = process.env.NODE_ENV !== 'production';

export * from './actions';

export default createStore({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state() {
    return { status: '' };
  },
  mutations: {
    [CHANGE_STATE]: (state: { status: string }, status = ''): void => {
      state.status = status;
    },
  },
  actions: {
    [CHANGE_STATE]: ({ commit }, value) => {
      commit(CHANGE_STATE, value);
    },
  },
  getters: {
    status(state): string {
      return state.status;
    },
  },
  modules: {
    version,
    user,
    auth,
    admin,
    formulas,
    powerLevels,
    classes,
    shops,
    cart,
    characters,
    nav,
  },
  strict: debug,
});
