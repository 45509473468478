import { NavList } from '@fm-tools/core';
import { Role } from './models';

export const NAV: NavList = [
  { icon: 'mdi-home', name: '', label: 'Home', title: 'Home', link: '/' },
  //{ icon: 'fa:fas fa-home', name: '', link: '/' },
  {
    icon: 'mdi-text-box-multiple-outline',
    name: 'Character App Maker',
    link: '/charapp',
  },
  {
    icon: 'mdi-calculator',
    name: 'EP Calculator',
    link: '/epcalc',
    title: 'Jogress/Xros EP Cost Calculator',
  },
  {
    icon: 'mdi-store',
    name: 'Shop',
    link: '/shop',
    title: "Omni's Shop District",
    auth: Role.Staff,
  },
  {
    icon: 'mdi-chart-bar',
    name: 'Stats',
  },
  {
    icon: 'mdi-cog',
    name: 'Admin CP',
    link: '/admin',
    title: 'Admin CP',
    auth: Role.Staff,
  },
];

export const ACCOUNT: NavList = [
  { icon: 'mdi-account-key', name: 'Login', link: '/login' },
  {
    icon: 'mdi-account-plus',
    name: 'Register',
    link: '/register',
  },
  {
    icon: 'mdi-account',
    name: 'Profile',
    link: '/profile',
    auth: true,
  },
  {
    icon: 'mdi-bag-personal',
    name: 'Inventory',
    link: '',
    auth: true,
  },
  {
    icon: 'mdi-account-off',
    name: 'Sign Out',
    link: '#',
    auth: true,
  },
];

export const ADMIN_NAV: NavList = [
  {
    icon: 'mdi-account-box',
    name: 'Edit Member',
    link: '/members',
    auth: Role.Staff,
  },
  {
    icon: 'mdi-pencil',
    name: 'Audit Logs',
    link: '/audits',
    auth: Role.Staff,
  },
  {
    icon: 'mdi-account-multiple',
    name: 'Edit Classes',
    link: '/classes',
    auth: Role.Admin,
  },
  {
    icon: 'mdi-function-variant',
    name: 'Edit Formulas',
    link: '/formulas',
    auth: Role.Admin,
  },
  {
    icon: 'mdi-lightning-bolt',
    name: 'Edit Power Levels',
    link: '/power-levels',
    auth: Role.Admin,
  },
  {
    icon: 'mdi-cart',
    name: 'Edit Shops',
    link: '/shops',
    auth: Role.Admin,
  },
  {
    icon: 'mdi-form-select',
    name: 'Edit Forms',
    auth: Role.Admin,
  },
];

export const ADMIN_MEMBER_NAV: { icon: string; name: string }[] = [
  {
    icon: '',
    name: 'Find Member',
  },
  {
    icon: '',
    name: 'Member List',
  },
];

export const PROFILE_NAV: NavList = [
  {
    icon: '',
    name: 'Profile',
    title: 'My Profile',
    link: '/profile',
  },
  {
    icon: '',
    name: 'My Characters',
    tab: 'tab-Characters',
  },
];
