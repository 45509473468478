export const ADMIN_USER_REQUEST = 'ADMIN_USER_REQUEST';
export const ADMIN_USER_SUCCESS = 'ADMIN_USER_SUCCESS';
export const ADMIN_USER_ERROR = 'ADMIN_USER_ERROR';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';

export const ADMIN_NEW_CHARACTER = 'ADMIN_NEW_CHARACTER';
export const ADMIN_UPDATE_CHARACTER = 'ADMIN_UPDATE_CHARACTER';
export const ADMIN_NEW_PARTNER = 'ADMIN_NEW_PARTNER';
export const ADMIN_UPDATE_PARTNER = 'ADMIN_UPDATE_PARTNER';
export const ADMIN_DELETE_CHARACTER = 'ADMIN_DELETE_CHARACTER';
export const ADMIN_DELETE_PARTNER = 'ADMIN_DELETE_PARTNER';

export const FETCH_AUDITS = 'FETCH_AUDITS';
