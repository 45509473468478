export const SHOP_REQUEST = 'SHOP_REQUEST';
export const SHOP_SUCCESS = 'SHOP_SUCCESS';
export const CREATE_SHOP = 'CREATE_SHOP';
export const CREATE_SHOP_ITEM = 'CREATE_SHOP_ITEM';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const UPDATE_SHOP_ITEM = 'UPDATE_SHOP_ITEM';
export const DELETE_SHOP = 'DELETE_SHOP';
export const DELETE_SHOP_ITEM = 'DELETE_SHOP_ITEM';

export const UPLOAD_SHOP_JSON = 'UPLOAD_SHOP_JSON';
